@import "~carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss";
@import "~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss";
@import "~carbon-components/scss/globals/scss/typography.scss";
@import "~carbon-components/scss/globals/scss/vars.scss";
@import "carbon-components/scss/globals/grid/grid";

$small: 320px;
$medium: 992px;
$tablet: 800px;
$smartphone: 550px;
$mediumscreen: 1100px;
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@import "./Landing.scss";
