// Carbon Typographyh
// https://www.carbondesignsystem.com/guidelines/typography/productive

// Header
.bx--header .bx--header__name {
  @include carbon--type-style("expressive-heading-04");
  font-size: 1rem;
}

.bx--header__nav {
  display: block;
  position: absolute;
  right: 95px;
}

// New Banner Responsive

.landing__banner {
  display: block;
  height: 100vh;
  width: 100%;
  background-image: url(../content/assets/pyrrha-hero.jpg);
  background-position-y: 0px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  background-size: cover;
  margin-top: 7rem;
  margin-bottom: 7rem;

  @media only screen and (max-width: $tablet) {
    height: 60vh;
    background-attachment: initial;
  }

  @media only screen and (max-width: $smartphone) {
    margin-bottom: 5rem;
    background-attachment: initial;
  }
}

// Banner Grid

.col__banner__title {
  padding-top: 15%;
}

.col__banner__subtitle {
  padding-top: 15%;

  @media only screen and (max-width: $mediumscreen) {
    display: none;
  }
}

// Banner Title Responsive

.BannerTitle {
  @include carbon--type-style("expressive-heading-06");
  display: block;
  font-size: 10rem;
  position: absolute;

  @media only screen and (max-width: $smartphone) {
    font-size: 6rem;
  }
}

// Banner Subtitle Responsive Widescreen

.BannerSubtitle {
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0;
  border-left: 4px solid;
  padding-left: 2rem;
  font-size: 2.5rem;
}

// Banner Subtitle Responsive Laptop Tablet Mobile

.BannerSubtitle_Responsive {
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0;
  border-left: 4px solid;
  padding-left: 2rem;
  font-size: 2.5rem;
  display: none;
  margin-top: 14rem;

  @media only screen and (max-width: $mediumscreen) {
    display: block;
  }

  @media only screen and (max-width: $tablet) {
    display: block;
    margin-top: 12rem;
  }

  @media only screen and (max-width: 700px) {
    display: block;
    font-size: 2rem;
  }

  @media only screen and (max-width: $smartphone) {
    display: block;
    font-size: 1.5rem;
    margin-top: 10rem;
  }

  @media only screen and (max-width: $small) {
    display: block;
    font-size: 1.3rem;
    margin-top: 9rem;
  }
}

.landing__diagram {
  display: flex;
  justify-content: center;
  background-color: #cc3d3d;
  padding: 1rem;
  margin-top: 5rem;

  .landing__diagramContainer {
    max-width: 100rem;

    img {
      width: 100%;
    }
  }
}

.landing__whoWeAre {
  h3 {
    @include carbon--type-style("expressive-heading-05");
    font-size: 2.1rem;
    margin: 0.1rem 0 2.5rem 0;
  }

  p {
    @include carbon--type-style("productive-heading-06");
    line-height: 1.4;
    font-size: 1.9rem;
    margin-bottom: 1rem;
    float: left;
    width: 100%;
    display: inline-flex;
  }

  p:first-of-type {
    margin-bottom: 2rem;
    float: right;
  }

  p1 {
    @include carbon--type-style("productive-heading-06");
    line-height: 1.4;
    font-size: 1.9rem;
    float: right;
    // display: inline-block;
    // width: %;
    //margin-left: 100rem;
    //display: inline-block;
  }

  svg {
    margin: 2rem 0 8rem 0;
  }

  .landing__buttonSet {
    margin-top: 3rem;
    margin-bottom: 4rem;
    float: left;

    .bx--btn--primary {
      background-color: #cc3d3d;
      max-width: 14rem;
    }

    @media screen and (max-width: 778px) {
      flex-direction: column;

      .bx--btn,
      .bx--btn--primary {
        max-width: none;
        margin: 1rem 0;
      }
    }

    .bx--btn--primary:hover {
      background-color: #5f6163;
    }
  }
}

// Sensor information section.
.info-section {
  margin-bottom: 10vw;

  .info-text {
    padding-top: 40px;
    border-top: 1px solid #cc3d3d;
    div[class^="bx--col"] {
      margin-top: 10px;
    }
  }

  .sensor-image {
    margin-top: 2vw;
    margin-bottom: 1.5vw;
    width: 100%;
  }

  .device {
    margin-top: 1vw;
    margin-bottom: 1.5vw;
    width: 100%;
  }

  .dash {
    margin-top: 1vw;
    margin-bottom: 4vw;
    width: 100%;
  }

  .testing {
    margin-top: 1vw;
    margin-bottom: 1.5vw;
    width: 100%;
  }

  .archdescrip {
    margin-bottom: 4vw;
  }

  /*.tests {
    margin-bottom: -11vw;
  }
*/

  button {
    width: 100%;
    background-color: inherit;
    border: 2px solid #cc3d3d;
  }

  // Dashboard information section
  .dashboard-image {
    margin-top: 5vw;
    width: 100%;
  }

  //alignment for the team images/text

  //Responsiv Column Team section

  .col__media {
    padding-right: 0;
  }

  .gallery {
    margin-top: 3rem;
    float: left;
    padding: 0 43px 0 0;
  }

  .desc {
    text-align: center;
    @include carbon--type-style("body-short-02");
    padding-top: 1rem;
  }
}
footer {
  background-color: #cc3d3d;
  border-top: 1px solid #cc3d3d;
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    text-align: center;
  }

  a {
    text-decoration: none;
    color: #393e40;
  }

  h4 {
    margin: 2rem 1rem;
    font-size: 1.5rem;
  }

  li {
    margin: 2rem 1rem;
    font-size: 1.25rem;
  }

  p {
    margin: 0rem 1rem;
    line-height: 1.1;
  }

  small {
    margin: 1rem 1rem 3rem 1rem;
  }
}
